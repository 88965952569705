import React, { Component } from "react";

export class ProductsAR extends Component {
  render() {
    return (
      <div id="products" className="text-center" style={{direction: "rtl"}}>
        <div className="container">
          <div className="section-title">
            <h2>منتجاتنا </h2>
            <p>
              {this.props.data ? this.props.data.text_1 : 'loading...'}            
            </p>
          </div>
          <div id="our-products-ar">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-12"> 
                  <div className="our-products-text">
                    <h2>{this.props.data ? this.props.data.product_1_title : 'loading...'}</h2>
                    <h4>{this.props.data ? this.props.data.product_1_summary : 'loading...'}</h4>
                    <p>{this.props.data ? this.props.data.product_1_description : 'loading...'}</p>
                  </div> 
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-12"> 
                  <div className="our-products-text">
                    <h2>{this.props.data ? this.props.data.product_2_title : 'loading...'}</h2>
                    <h4>{this.props.data ? this.props.data.product_2_summary : 'loading...'}</h4>
                    <p>{this.props.data ? this.props.data.product_2_description : 'loading...'}</p>
                  </div> 
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-12"> 
                  <div className="our-products-text">
                    <h2>{this.props.data ? this.props.data.product_3_title : 'loading...'}</h2>
                    <h4>{this.props.data ? this.props.data.product_3_summary : 'loading...'}</h4>
                    <p>{this.props.data ? this.props.data.product_3_description : 'loading...'}</p>
                  </div> 
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-12"> 
                  <div className="our-products-text">
                    <h2>{this.props.data ? this.props.data.product_4_title : 'loading...'}</h2>
                    <h4>{this.props.data ? this.props.data.product_4_summary : 'loading...'}</h4>
                    <p>{this.props.data ? this.props.data.product_4_description : 'loading...'}</p>
                  </div> 
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-12"> 
                  <div className="our-products-text">
                    <h2>{this.props.data ? this.props.data.product_5_title : 'loading...'}</h2>
                    <h4>{this.props.data ? this.props.data.product_5_summary : 'loading...'}</h4>
                    <p>{this.props.data ? this.props.data.product_5_description : 'loading...'}</p>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductsAR;
