import React, { Component } from "react";


export class NavigationAR extends Component {


  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header navbar-right">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              style={{float: "left"}}
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <div className="logo-container" 
            width="auto" 
            height="50px"
            style={{right: "10", display: "block"}}
            >
              <a href="#page-top" className="logo-image">
                <img src="img/logo.svg" alt="biocare-logo"></img>
              </a>
            </div>
          </div>
          <div
            className="collapse navbar-collapse navbar-left"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-left navbar-mobile-reverse navbar-ar">
              <li>
                <a href="/" className="page-scroll" onClick={this.change}>
                English
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                للتواصل
                </a>
              </li>
              <li>
                <a href="#clients" className="page-scroll">
                عملائنا
                </a>
              </li>
              <li>
                <a href="#gallery" className="page-scroll">
                المعرض
                </a>
              </li>
              <li>
                <a href="#products" className="page-scroll">
                منتجاتنا
                </a>
              </li>
              <li>
                <a href="#quality-safety" className="page-scroll">
                الجودة والسلامة
                </a>
              </li>
              <li>
                <a href="#about" className="page-scroll">
                عنا
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavigationAR;

