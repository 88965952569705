import React from 'react';
import emailjs from 'emailjs-com';


export default function ContactAR() {

  function sendEmail(e) {
    e.preventDefault();
 

    emailjs.sendForm('service_i587l4a','template_6if0ln8', e.target, 'user_jVxZbyMfkf5yciYw5vCNG')
      .then((result) => {
          window.alert('Email sent!')
          document.getElementById("contactForm").reset(); 
      }, (error) => {
          console.log(error.text);
      });
  }

  return (
      <div dir="rtl">
        <div id="contact" style={{direction: "rtl" }}> 
          <div className="container" >
            <div className="section-title-ar">
              <h2>تواصل معنا</h2>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <p>
                  برجاء ملء النموذج أدناه لإرسال رسالة إلينا و سنقوم بالرد عليكم في أقرب وقت ممكن.  
                  </p>
                </div>
                <form name="sentMessage" id="contactForm" Validate onSubmit={sendEmail}>
                  <div className="row reverse-on-mobile">
                  <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          type="tel"
                          id="phone"
                          className="form-control"
                          placeholder="التليفون"
                          required="required"
                          name="phone_number"                       />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="الاسم"
                          required="required"
                          name="user_name"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="البريد الالكتروني"
                          required="required"
                          name="user_email"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="الرسالة "
                      required
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                  أرسل
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info" style={{bottom: "80"}}>
              <div className="contact-item">
                <h3>للتواصل </h3>
                <p>
                  <span>
                   العنوان  <i className="fa fa-map-marker"></i> 
                  </span>
                  <a href="https://www.google.com/maps/place/Biocare+Pharma+Group/@29.9854317,31.3094947,21z/data=!4m5!3m4!1s0x14583955104da1e1:0xf2fab4c52376277b!8m2!3d29.9854189!4d31.3095411"   
                     target="_blank" rel="noopener noreferrer">                  
                    <p>2110 شارع الأرقم إبن أبي الأرقم، المعراج، القاهرة، مصر </p>
                  </a>
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    التليفون <i className="fa fa-phone"></i> 
                  </span>{" "}
                  0992 004 100 20+
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                     البريد الالكتروني <i className="fa fa-envelope-o"></i>
                  </span>{" "}
                  ehab_salama80@hotmail.com
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/Biocare-Pharma-Group-268803763627370/"
                        target="_blank" rel="noopener noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              بايوكير فارما &copy; 2021، تصميم و تنفيذ  {" "}
              <a href="https://www.linkedin.com/in/ali-sahmoud/" target="_blank" rel="noopener noreferrer">
              علي سهمود 
              </a>
            </p>
          </div>
        </div>
      </div>
    );
}
