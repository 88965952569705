import React, { Component } from "react";

export class Clients extends Component {
  render() {
    return (
      <div id="clients">
        <div className="container">
          <div className="section-title text-center">
            <h2>our Clients</h2>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-lg-4">
                    <div className="clients">
                      <div className="clients-container">
                        <div className="clients-image">
                          {" "}
                          <img src={d.img} alt="" />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default Clients;
