import React, { Component } from 'react'  
import Navigation from './components/Navigation';
import Header from './components/Header';
import About from './components/About';
import QualitySafety from './components/QualitySafety';
import Products from './components/Products';
import Gallery from './components/Gallery';
import Clients from './components/Clients';
import Contact from './components/Contact';
import JsonData from './data/data.json';


export class AppEN extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div>
        <Navigation />
        <Header data={this.state.landingPageData.Header} />
        <About data={this.state.landingPageData.About} />
        <QualitySafety data={this.state.landingPageData.QualitySafety} />
        <Products data={this.state.landingPageData.Products} />
        <Gallery />
        <Clients data={this.state.landingPageData.Clients} />
        <Contact data={this.state.landingPageData.Contact} />
      </div>
    )
  }
}

export default AppEN;
