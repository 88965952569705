import React, { Component } from "react";

export class About extends Component {
  render() {
    return (
      <div id="about" className="text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-md-offset-1 section-title"> 
              <h2>{this.props.data ? this.props.data.about_title : 'loading...'}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-md-offset-1"> 
              <p>{this.props.data ? this.props.data.about : 'loading...'}</p>
            </div>
          </div>

          <div id="mission">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-6"> <img src="img/mission.jpg" className="img-responsive" alt=""/> </div>
                <div className="col-xs-12 col-md-6">
                  <div className="mission-text">
                    <h2>{this.props.data ? this.props.data.mission_title : 'loading...'}</h2>
                    <p>{this.props.data ? this.props.data.mission : 'loading...'}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-10 col-md-offset-1 section-title"> 
            <h2>{this.props.data ? this.props.data.milestones_title : 'loading...'}</h2>
            </div>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.milestones.map((d,i) => (
                  <div  key={`${d.title}-${i}`} className="col-sm-6 col-md-4 col-lg-4">
                    {" "}
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
          
          <div id="ceo-message">
            <div className="container">
              <div className="row">
              <div className="col-xs-12 col-md-6">
                  <div className="ceo-message-text">
                    <h2>{this.props.data ? this.props.data.ceo_message_title : 'loading...'}</h2>
                    <p>{this.props.data ? this.props.data.ceo_message_1 : 'loading...'}</p>
                    <p>{this.props.data ? this.props.data.ceo_message_2 : 'loading...'}</p>
                    <p>{this.props.data ? this.props.data.ceo_message_3 : 'loading...'}</p>
                    <h5>{this.props.data ? this.props.data.ceo_signature_name : 'loading...'}</h5>
                    <h5>{this.props.data ? this.props.data.ceo_signature_title : 'loading...'}</h5>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6"> <img src="img/ceo.jpg" className="img-responsive" alt=""/> </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

export default About;
