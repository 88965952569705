import React, { Component } from 'react'  
import NavigationAR from './components/NavigationAR';
import HeaderAR from './components/HeaderAR';
import AboutAR from './components/AboutAR';
import QualitySafetyAR from './components/QualitySafetyAR';
import ProductsAR from './components/ProductsAR';
import GalleryAR from './components/GalleryAR';
import ClientsAR from './components/ClientsAR';
import ContactAR from './components/ContactAR';
import JsonData from './data/dataAR.json';


export class AppAR extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div dir="rtl">
        <NavigationAR />
        <HeaderAR data={this.state.landingPageData.Header} />
        <AboutAR data={this.state.landingPageData.About} />
        <QualitySafetyAR data={this.state.landingPageData.QualitySafety} />
        <ProductsAR data={this.state.landingPageData.Products} />
        <GalleryAR />
        <ClientsAR data={this.state.landingPageData.Clients} />
        <ContactAR data={this.state.landingPageData.Contact} />
      </div>
    )
  }
}

export default AppAR;
