import React, { Component } from 'react'

export class QualitySafetyAR extends Component {
  render() {
    return (
        <div id="quality-safety">
        <div className="container">
        <div className="row">
          <div className="section-title text-center"> 
            <h2>الجودة والسلامة</h2>
          </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/examination.jpg" className="img-responsive" alt=""/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="row">
                <p>{this.props.data ? this.props.data.paragraph1 : 'loading...'}</p>
              </div>
              <div className="row">
                <p>{this.props.data ? this.props.data.paragraph2 : 'loading...'}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/warehouse.png" className="img-responsive" alt=""/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="row">
                <p>{this.props.data ? this.props.data.paragraph3 : 'loading...'}</p>
              </div>
              <div className="row">
                <p>{this.props.data ? this.props.data.paragraph4 : 'loading...'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default QualitySafetyAR

