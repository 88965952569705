import React, { Component } from "react";


export class Navigation extends Component {


  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <div className="logo-container" width="auto" height="50px">
              <a href="#page-top" className="logo-image">
                <img src="img/logo.svg" alt="biocare-logo"></img>
              </a>
            </div>
          </div>
          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="#about" className="page-scroll">
                  About
                </a>
              </li>
              <li>
                <a href="#quality-safety" className="page-scroll">
                  Quality & Safety
                </a>
              </li>
              <li>
                <a href="#products" className="page-scroll">
                  Products
                </a>
              </li>
              <li>
                <a href="#gallery" className="page-scroll">
                  Gallery
                </a>
              </li>
              <li>
                <a href="#clients" className="page-scroll">
                  Clients
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                  Contact
                </a>
              </li>
              <li>
                <a href="/ar" className="page-scroll" onClick={this.change}>
                العربية
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;

