import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Importing routing components
import AppEN from "./AppEN";
import AppAR from "./AppAR";


// Main Application component where the website is routed by different URLs
class App extends Component {
  render() {
    const supportsHistory = "pushState" in window.history;
    return (
      <div className="App">
        <BrowserRouter forceRefresh={!supportsHistory}>
          <Switch>
            <Route exact path="/" component={AppEN} />
            <Route exact path="/ar" component={AppAR} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;