import React, { Component } from "react";

export class GalleryAR extends Component {
  render() {
    return (
      <div id="gallery" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>معرض الصور</h2>
          </div>
          <div className="row">
            <div className="gallery-items">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="gallery-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/gallery/01.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>قاعة اجتماعات</h4>
                      </div>
                      <img
                        src="img/gallery/01.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="gallery-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/gallery/02.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>مدخل الشركة</h4>
                      </div>
                      <img
                        src="img/gallery/02.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="gallery-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/gallery/03.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>الاستقبال</h4>
                      </div>
                      <img
                        src="img/gallery/03.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="gallery-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/gallery/04.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>قاعة الاجتماعات الرئيسية</h4>
                      </div>
                      <img
                        src="img/gallery/04.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GalleryAR;
